<template>
  <div class="text-gray-900">
    <div class="rounded-xl bg-brick-300 p-4 text-start md:w-full">
      <p class="mb-1 font-medium leading-tight md:text-xl xl:text-2xl">
        Im Schnitt {{ store.reimbursement }}&nbsp;% Rückerstattung
      </p>
      <p class="text-xs leading-tight md:text-lg lg:text-xl">
        {{ info?.reimbursementText }}
      </p>
      <div class="mt-2">
        <button
          type="button"
          class="rounded-heavy border-black text-xs underline transition hover:bg-brick focus:outline-none focus:ring-2 focus:ring-gray-300 md:border-[1px] md:px-4 md:py-2 md:font-medium md:no-underline"
          @click="store.toggleModal">
          Mehr Info
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useReimbursementModalStore } from "~/store/reimbursementModal";
import type { ReimbursementInfo } from "~/types/strapi/components/block/interfaces/ReimbursementInfo";

const store = useReimbursementModalStore();

defineProps<{
  info: ReimbursementInfo | null;
}>();

onMounted(() => {
  store.$reset();
});
</script>

<style lang="scss" scoped></style>
